<template>
    <div class="p-col-12 p-card relative page-container">
<!--        <div class="p-d-flex"><breadcrumb :class="'p-col-12'" :home="home" :model="breadcrumb" /></div>-->
<!--        <div class="p-datatable-loading-overlay p-component-overlay" v-if="loading">-->
<!--            <img src="@/assets/img/spiner-loader.png" alt="loading" />-->
<!--        </div>-->
<!--        <TabMenu v-if="showTabs" :model="tabs" />-->

        <router-view :loading="loading" @loadingChange="loadingChange" />
    </div>
</template>

<script>
import { getUserById } from '@/api/user';
import { requestToastHandler } from '@/main/mixins';
export default {
    name: 'index',
    mixins: [requestToastHandler],
    data: () => ({
        filters: {},
        loading: false,
        userTitle: '',
        home: { to: '/', icon: 'pi pi-home' },
        tabs: [
            {
                label: 'Список',
                to: '/users/list',
            },
            {
                label: 'Роли',
                to: '/users/roles/list',
            },
            {
                label: 'Пароли',
                to: '/users/passwords',
            },
        ],
    }),
    methods: {
        async updateUserTitle() {
            if (!this.userId) return;

            try {
                const userId = await getUserById(this.userId);
                if (!userId.data) return;
                let userFirstName = userId.data.attributes.firstName ? userId.data.attributes.firstName : 'Имя';
                let userLastName = userId.data.attributes.lastName ? userId.data.attributes.lastName : 'Фамилия';
                let login = userId.data.attributes.login ? userId.data.attributes.login : '';
                this.userTitle = `${login}(${userFirstName} ${userLastName})`;
            } catch (error) {
                this.$requestError(error.message);
            }
        },
        loadingChange(payload = false) {
            this.loading = payload;
        },
    },
    computed: {
        userId() {
            return this.$route.params.userId;
        },
        breadcrumb() {
            const re = /:userId/gi;
            const params = { ...this.$route.params };

            return this.$route.meta.breadcrumb.map((breadcrumb) => {
                if (breadcrumb.to.indexOf(':userId') >= 0) {
                    return {
                        ...breadcrumb,
                        label: breadcrumb.label == 'Редактирование' ? this.userTitle : breadcrumb.label,
                        to: breadcrumb.to.replace(re, params.userId),
                    };
                }

                return breadcrumb;
            });
        },
        showTabs() {
            return ['/users/list', '/users', '/users/roles/list', '/users/passwords'].includes(this.$route.path);
        },
        saveTabs() {
            return [`/users/${this.userId}/userParams`, `/users/${this.userId}/rightsByForm`];
        },
    },
    beforeRouteUpdate(to, from, next) {
        this.loading = true;
        if (!this.saveTabs.includes(to.fullPath)) this.userTitle = '';

        next();

        // если идем куда то кроме табов очищать
    },
    watch: {
        userId(newId) {
            if (newId) {
                this.updateUserTitle();
            }
        },
    },
    mounted() {
        if (this.userId) {
            this.updateUserTitle();
        }
    },
};
</script>

<style lang='scss' scoped>
.relative {
    position: relative;
}
.p-component-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}
.pi-spinner {
    font-size: 2rem;
}
</style>
